import { NgModule } from '@angular/core';
import { PermissionDirective } from './permission.directive';

@NgModule({
    imports: [],
    declarations: [PermissionDirective],
    exports: [PermissionDirective]
})
export class SharedDirectivesModule {
    // Dung de dang ky cac directive
   
}
