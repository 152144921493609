import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public _menuShow$ = new BehaviorSubject<any>(null);
    constructor(private http: HttpClient) { }


    setMenuShow(val: any) {
        this._menuShow$.next(val);
    }

    getMenuShow(): Observable<any> {
        return this._menuShow$.asObservable();
    }


}
