import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';
import { catchError, tap } from 'rxjs/operators';
import { Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from '@environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthService,
        private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = request.clone();
        if (request.url.includes('api.openai.com')) {
            authReq = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${environment?.SECRET_KEY}`
                }
            });
        }
        else
            if (request.url.includes('Authenticate')) {
                // return request
            }
            else {
                authReq = request.clone({
                    setHeaders: {
                        Authorization: `${this._authService.authorizationHeaderValue}`
                    }
                });

            }
        return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));

    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            //   this._router.navigate(['/login']);
            return of(err.message);
        }
        return throwError(err);
    }
}
