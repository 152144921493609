import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { MenuService } from '@app/shared/services/digital-gold/menu.service';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit {
    fuseConfig: any;
    navigation: any;
    menuShow: boolean = true
    constructor(
        private menuService: MenuService
    ) {
        this.menuService.getMenuShow().subscribe((config) => {
            if (config == true || config == false)
                this.menuShow = config;
        });
    }
    ngOnInit(): void {
    }
}
