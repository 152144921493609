
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    maxNcc: 10,
    SECRET_KEY:'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',
    listApiNotShowLoading:['BuyPrice','home','api.openai.com'],
    //apiUrl: 'http://10.1.0.10:5301'
    //  apiUrl: 'http://10.1.3.122:15200'
    //  apiUrl: 'http://10.1.3.122:5200'
    // apiUrl: 'http://10.1.117.185:15200'
    // apiUrl: 'http://10.1.117.143:8089'
    apiUrl: 'https://dev-digigold.congbaohiem.com.vn'
    //apiUrl: 'https://localhost:44380'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
