import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
    @Input() appFunction: string;
    @Input() appAction: string;

    constructor(private el: ElementRef, private authService: AuthService) {

    }

    ngOnInit(): void {
    }
}
