import { Component, OnInit, OnDestroy } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as vietnam } from './i18n/vi';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { User, Company } from '@app/shared/models';
import { AuthService,  } from '@app/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ImisNavigation } from '@app/navigation/navigation';
import { fuseAnimations } from '@fuse/animations';



@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: fuseAnimations
})
export class HomeComponent {
    navigation: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      
        private _imisNavigation: ImisNavigation
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(vietnam, english, turkish);
    }

}
