import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '@app/shared/services/digital-gold/menu.service';
import { SanPhamService } from '@app/shared/services/digital-gold/product/sanpham.service';

@Component({
    selector: 'app-transaction-detail',
    templateUrl: './transaction-detail.component.html',
    styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TransactionDetailComponent>) { }

    ngOnInit(): void {
        console.log(this.data);
    }
    close(type: any) {
        this.dialogRef.close(type);
    }
}