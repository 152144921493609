import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from '@app/shared/directives/shared-directives.module';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/shared/guard';
import { HomeComponent } from './digital-gold/home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { MaterialModule } from '@app/material.module';
import { ChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./digital-gold/digital-gold.module').then(m => m.DigitalGoldModule),
        // canActivateChild: [AuthGuard],
    },
    {
        path: 'digital-gold',
        loadChildren: () => import('./digital-gold/digital-gold.module').then(m => m.DigitalGoldModule),
        // canActivateChild: [AuthGuard],
    },
];
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        MatChipsModule,
        MaterialModule,
        FormsModule,
        MatTreeModule,
        MatInputModule,
        NgxMatSelectSearchModule,
        MatRadioModule,
        ChartsModule,
        SharedDirectivesModule,
        
    ],
    declarations: []
})
export class PagesModule {
}