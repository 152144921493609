import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse,
    HttpHeaderResponse, HttpResponseBase, HttpResponse
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class RequestResponseHandlerInterceptor implements HttpInterceptor {
    apiConfig: any = {}
    public pendingRequest: number = 0;
    public showLoading: boolean = false;

    constructor(private spinnerService: NgxSpinnerService) {
        //this.apiConfig = this.appConfigService.getAppConfig().api;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.checkListApiNotShowLoading(request.url)) {
            this.pendingRequest++;
            return next.handle(request).pipe(tap((res: any) => {
                this.turnOnModal();
            }, (err: any) => {
                // this.turnOffModal();
            })).pipe(
                finalize(() => {
                    this.turnOffModal();
                }))
        } else {
            return next.handle(request).pipe(tap((res: any) => {
            }, (err: any) => {
            }))
        }
    }
    checkListApiNotShowLoading(url) {
        let listApiNotShowLoading = environment?.listApiNotShowLoading;
        if (listApiNotShowLoading?.length) {
            for (const item of listApiNotShowLoading) {
                if (url.includes(item)) {
                    return false;
                }
            }
        }
        return true;
    }
    turnOnModal() {
        // if (!this.showLoading) {
        //   this.showLoading = true;
        this.spinnerService.show("sp3");
        // }
        // this.showLoading = true;
    }

    turnOffModal() {
        this.pendingRequest--;
        if (this.pendingRequest <= 0) {
            // if (this.showLoading) {
            this.spinnerService.hide("sp3");
            // }
            // this.showLoading = false;
        }
    }

}
