import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiResult, LoginRequest, User, UserExtend, Company } from '@app/shared/models';
import { BaseService } from '@app/shared/services';


@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
    private _authUserSubject: BehaviorSubject<User>;
    public _authUser: Observable<User>;
    private _curCompanySubject: BehaviorSubject<Company>;
    public _curCompany: Observable<Company>;
    private _curMenuSubject: BehaviorSubject<string>;
    public _curMenu: Observable<string>;

    constructor(private _http: HttpClient) {
        super();

        this._authUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this._authUser = this._authUserSubject.asObservable();
    }

    login(req: LoginRequest): any {
        return this._http.post<any>(`${this.apiUrl}/Admin/Account/Login`, req)
            .pipe(map(result => {
                let user: any = {}
                if (result.data.token) {

                    // const helper = new JwtHelperService();
                    // const decodedToken = helper.decodeToken(result.resultObj);
                    // const expirationDate = helper.getTokenExpirationDate(result.resultObj).getTime();
                    // const isExpired = helper.isTokenExpired(result.resultObj);

                    user.token = result.data.token;
                    // user.msisdn = req.msisdn;
                    user.isAuthenticated = result.data.isAuthenticated;
                    user.description = result.data.description;
                    user.userName = result.data.userName;
                    user.fullName = result.data.fullName;
                    user.avatar = result.data.avatar;
                    user.expiration = result.data.expiration;
                    localStorage.setItem('user', JSON.stringify(user));
                    this._authUserSubject.next(user);
                }
                else {
                    localStorage.removeItem('user');
                    this._authUserSubject.next(null);
                }
                return { statusCode: result.statusCode, message: result.message, resultObj: user };
            }));
    }



    setMenu(menuid: string): void {
        localStorage.setItem('menu', menuid);
        this._curMenuSubject.next(menuid);
    }

    isAuthenticated(): boolean {
        return this.user != null && this.user.token != null;
    }

    get expirationDate(): boolean {
        return this.user.expirationDate > new Date().getTime() ? true : false
    }
    get user(): User {
        return this._authUserSubject.value;
    }

    get company(): Company {
        return this._curCompanySubject.value;
    }

    get menu(): string {
        return this._curMenuSubject.value;
    }

    get authorizationHeaderValue(): string {
        // console.log(this.user?.token);
        return `Bearer ${JSON.parse(localStorage.getItem('user'))?.token}`;
        // return `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJz dWIiOiIwOTYzNzEyMDAxIiwianRpIjoiMDE0ZTRhZTQtOWFlOC00ZDI5LWJlNDMtNzJkYjc0M2VmMzk4IiwiZW1haWwiOiIiLCJuYW1laWQiOiIwMTRlNGFlNC05YWU4LTRkMjktYmU0My03MmRiNzQzZWYzOTgiLCJ1bmlxdWVfbmFtZSI6IjA5NjM3MTIwMDEiLCJpcCI6IjE5Mi4xNjguMS42OCIsInJvbGVzIjoiQ3VzdG9tZXIiLCJleHAiOjE2NzAzODA5NjMsImlzcyI6IkRpZ2lHb2xkQVBJIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NTAwMSJ9.60LK_R2BIdMd6KuPamFLTSOTYNqIfpcWsQT0rz8KFFg`;
    }

    get userName(): string {
        return this.user != null ? this.user.userName : '';
    }
    logout(): void {
        // remove user from local storage to log user out
        this._authUserSubject.next(null);
        localStorage.clear();

        // localStorage.removeItem('user');
        // localStorage.removeItem('userExtend');
        // localStorage.removeItem('company');
    }
}

