import { HttpParams } from '@angular/common/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BaseService, } from '..';

@Injectable({
    providedIn: 'root'
})
export class ChucNangService extends BaseService {

    private sharedHeaders = new HttpHeaders();
    constructor(private _http: HttpClient) {
        super();
        this.sharedHeaders = this.sharedHeaders.set(
            "Content-Type",
            "application/json"
        );
    }
    getChucNang(): any {
        let params = new HttpParams();
        let nhomQuyen = this.localUser.idNhomQuyen;
        return this._http
            .get<any>(`${this.apiUrl}/api/chucnang/${nhomQuyen}`, {
                headers: this.sharedHeaders,
                params: params,
            })
            .pipe(catchError(this.handleError));

    }
    getChucNangAll(): any {
        let params = new HttpParams();
        // let nhomQuyen = this.localUser.idNhomQuyen;
        return this._http
            .get<any>(`${this.apiUrl}/api/chucnang/-1`, {
                headers: this.sharedHeaders,
                params: params,
            })
            .pipe(catchError(this.handleError));

    }
}
