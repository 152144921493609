import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AuthService } from '@app/shared/services';
import { SystemConstants } from '@app/shared/constants';
import { CommandCode } from '../constants/commandcode';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
    user: any
    constructor(private router: Router, private authService: AuthService) {
        this.user = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null
    }
    canLoad(): boolean | Observable<boolean> | Promise<boolean> {
        if (!this.user || !this.user.token) {
            // this.router.navigate(['/index']);
            return true;
        } else {
            // this.router.navigate(['/main']);
            return false;
        }
    }

    canActivate(): boolean | Observable<boolean> | Promise<boolean> {
        if (this.user && this.user.token) {
            console.log(new Date(this.user.expiredAt) >= new Date());
            if (new Date(this.user.expiredAt) >= new Date()) {
                return true;
            } else {
                // this.router.navigate(['/index/login']);
                this.router.navigate(['/digital-gold/landing']);
                return false;
            }
        } else {
            this.router.navigate(['/digital-gold/landing'], { replaceUrl: true });
            return false;
        }
    }
    canActivateChild() {
        if (this.user && this.user.token) {
            return true;
        } else {
            this.router.navigate(['/digital-gold/landing'], { replaceUrl: true });
            return false;
        }
    }
}
