import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
    }
    go(type){
        if(type == 1){
            this.router.navigate(['digital-gold/home']);
        }
        if(type == 2){
            this.router.navigate(['digital-gold/tinbai']);
        }
        if(type == 4){
            this.router.navigate(['digital-gold/historytransaction']);
        }
        if(type == 3){
            this.router.navigate(['digital-gold/transaction']);
        }
        if(type == 5){
            this.router.navigate(['digital-gold/property']);
        }
    }
}
