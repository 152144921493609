import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuBarComponent } from '@app/main/pages/digital-gold/menu-bar/menu-bar.component';
import { MaterialModule } from '@app/material.module';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';

import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';

@NgModule({
    declarations: [
        VerticalLayout1Component,
        MenuBarComponent
    ],
    imports: [
        RouterModule,
        MaterialModule,
        FuseSharedModule,
        FuseSidebarModule,
        ContentModule,
    ],
    exports: [
        VerticalLayout1Component
    ]
})
export class VerticalLayout1Module {
}
